const baseUrl = process.env.REACT_APP_BASE_URL;

module.exports = {
  login: baseUrl + "login",
  clinicUserChangePassword: baseUrl + "web/changePassword",
  clinicUserForgotPassword: baseUrl + "web/forgotPassword",
  getDevices: baseUrl + "devices",
  getDevice: baseUrl + "getDevice",
  getFirmwareUpdates: baseUrl + "firmwareUpdates",
  checkEmail: baseUrl + "web/checkEmail",
  getAssemblies: baseUrl + "assemblies",
  getAssembly: baseUrl + "getAssembly",
  createPdf: baseUrl + "createPdf",
  getSecondSetOfLabelingPdf: baseUrl + "devices/pdf/label",
  getPackagingReports: baseUrl + "devices/:deviceId/packaging/report",
  getAssemblyTests: baseUrl + "assemblies/:assemblyId/tests",
  getAssemblyTest: baseUrl + "assemblies/:assemblyId/tests/:assemblyTestId",
  getCalibrations: baseUrl + "devices/:deviceId/calibrations",
  getCalibration: baseUrl + "devices/:deviceId/calibrations/:calibrationId",
};
