import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { history } from "../../helpers";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  MenuItem,
  Menu,
  Typography,
  Divider
} from "@material-ui/core";

import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { logout } from "../../logout";
import { useDispatch, connect } from "react-redux";
import Popup from "components/Popup";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  topBarButtons: {
    marginLeft: theme.spacing(1),
    color: "white",
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
    color: "inherit",
  },
  header: {
    fontFamily: "Roboto",
    textDecoration: "none",
    color: "white",
    fontSize: "26px",
    letterSpacing: "1px",
  },
  paper: {
    overflow: 'visible',
    marginTop: theme.spacing(1),
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      zIndex: -1,
    },
  },
}));

function TopBar({ onOpenNavBarMobile, onOpenPatientListMobile, ...rest }) {
  const classes = useStyles();
  const currentType = rest.currentType;
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const openProfile = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickOpen = (value) => {
    setOpen(true);
    setType(value);
  };

  const handleRouteRedirect = () => {
    if (currentType === "DEVICES") {
      return "/main/devices/Information";
    } else {
      return "/main/assemblies/Information";
    }
  };

  return (
    <AppBar className={classes.root} color="primary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <RouterLink to={handleRouteRedirect}>
            <h2 className={classes.header}>Manufacturing Tool Dashboard</h2>
          </RouterLink>
        </Hidden>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          {history.location.pathname.includes("subjects") ? (
            <IconButton color="inherit" onClick={onOpenPatientListMobile}>
              <PeopleIcon />
            </IconButton>
          ) : null}
        </Hidden>

        <Button
          className={classes.topBarButtons}
          onClick={() => handleClickOpen(1)}
        >
          Firmware Update List
        </Button>

        <Popup type={type} open={open} setOpen={setOpen} />



        <Button
          id="profile-button"
          onClick={handleClick}
          color="inherit"
          aria-controls={openProfile ? 'profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openProfile ? 'true' : undefined}
        >
          <AccountBoxIcon />
          PROFILE
        </Button>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleClose}>
            {JSON.parse(localStorage.getItem("user")) ? (
              <div>
                <Typography variant="h5" color="textSecondary">
                  {JSON.parse(localStorage.getItem("user")).user.name +
                    " " +
                    JSON.parse(localStorage.getItem("user")).user.surname}
                </Typography>
                <Typography variant="body2">
                  {JSON.parse(localStorage.getItem("user")).user.type}
                </Typography>
              </div>
            ) : null}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            Logout
          </MenuItem>
        </Menu>

      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentType: state.currentTypeState.currentType,
  };
};

export default connect(mapStateToProps, null)(TopBar);
