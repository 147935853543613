import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assemblyPagination: { skip: 0, limit: 30 },
  devicesPagination: { skip: 0, limit: 30 },
  firmwarePagination: { skip: 0, limit: 30 },
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState: initialState,
  reducers: {
    setAssembliesPagination: (state, action) => {
      state.assemblyPagination = action.payload;
    },
    setDevicesPagination: (state, action) => {
      state.devicesPagination = action.payload;
    },
    setFirmwarePagination: (state, action) => {
      state.firmwarePagination = action.payload;
    },
    clearAssembliesPagination: (state, _action) => {
      state.assemblyPagination = { skip: 0, limit: 30 };
    },
    clearDevicesPagination: (state, _action) => {
      state.devicesPagination = { skip: 0, limit: 30 };
    },
    clearFirmwarePagination: (state, _action) => {
      state.firmwarePagination = { skip: 0, limit: 30 };
    },
  },
});

export const {
  setAssembliesPagination,
  setDevicesPagination,
  setFirmwarePagination,
  clearAssembliesPagination,
  clearDevicesPagination,
  clearFirmwarePagination,
} = paginationSlice.actions;
export default paginationSlice.reducer;
