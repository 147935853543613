import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { automaticService } from "services";
import { logout } from "../logout";

export const getCalibrations = createAsyncThunk(
  "getCalibrations",
  async ({ deviceId, skip, limit }, { rejectWithValue }) => {
    try {
      return await automaticService.getCalibrations(deviceId, skip, limit);
    } catch (error) {
      if (error.message) {
        if (error?.response?.status === 401) {
          logout();
        }
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  result: null,
  loading: false,
  error: null,
};

const calibrationsSlice = createSlice({
  name: "calibrations",
  initialState: initialState,
  reducers: {
    clearCalibrationsList: (state, _action) => {
      state.result = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalibrations.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getCalibrations.fulfilled, (state, action) => {
      state.loading = false;
      state.result = state.result
        ? state.result.concat(action.payload)
        : action.payload;
      state.error = null;
    });
    builder.addCase(getCalibrations.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    });
  },
});

export const { clearCalibrationsList } = calibrationsSlice.actions;
export default calibrationsSlice.reducer;
