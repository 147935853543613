import axios from "axios";
import api_constants from "apis/api_constants";

export const automaticService = {
  get_device,
  get_devices,
  get_assembly,
  get_assemblies,
  get_firmware_updates,
  getSecondSetOfLabelingPdf,
  getPackagingReports,
  getCalibrations,
  getCalibration,
  getAssemblyTests,
  getAssemblyTest,
};

async function get_device(deviceSerialNumber) {
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
    },
    data: JSON.stringify({
      deviceSerialNumber: deviceSerialNumber,
    }),
  };

  return await axios(api_constants.getDevice, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.device;
    });
}

async function get_devices(
  assemblyStatus,
  calibrationStatus,
  startDate,
  endDate,
  skip,
  limit,
  serialNumber,
  deviceType
) {
  const loginResponse = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginResponse.token}`,
    },
    data: JSON.stringify({
      manufacturerId: loginResponse.user._id,
      skip: skip,
      limit: limit,
      assemblyStatus: assemblyStatus,
      calibrationStatus: calibrationStatus,
      startDate: startDate,
      endDate: endDate,
      serialNumber: serialNumber,
      deviceType: deviceType,
    }),
  };

  return await axios(api_constants.getDevices, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.devices;
    });
}

async function get_assembly(pcbSerialNumber) {
  const loginResponse = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginResponse.token}`,
    },
    data: JSON.stringify({
      manufacturerId: loginResponse.user._id,
      pcbSerialNumber: pcbSerialNumber,
    }),
  };

  return await axios(api_constants.getAssembly, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.assembly;
    });
}

async function get_assemblies(
  assemblyStatus,
  oadUpdateStatus,
  startDate,
  endDate,
  skip,
  limit,
  serialNumber,
  pcbType
) {
  const loginResponse = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginResponse.token}`,
    },
    data: JSON.stringify({
      manufacturerId: loginResponse.user._id,
      skip: skip,
      limit: limit,
      assemblyStatus: assemblyStatus,
      oadUpdateStatus: oadUpdateStatus,
      startDate: startDate,
      endDate: endDate,
      pcbSerialNumber: serialNumber,
      pcbType: pcbType,
    }),
  };

  return await axios(api_constants.getAssemblies, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.assemblies;
    });
}

async function get_firmware_updates(skip, limit) {
  const requestOptions = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
    },
    data: JSON.stringify({
      skip: skip,
      limit: limit,
    }),
  };

  return await axios(api_constants.getFirmwareUpdates, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.updates;
    });
}

async function getSecondSetOfLabelingPdf(deviceSerialNumber) {
  const requestUrl = `${api_constants.getSecondSetOfLabelingPdf}/${deviceSerialNumber}.pdf`;
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
    },
    responseType: "arraybuffer",
    responseEncoding: "binary",
  };

  return await axios(requestUrl, requestOptions)
    .then(handleSecondSetOfLabelingResponse, (error) => Promise.reject(error))
    .then((data) => {
      return Buffer.from(data).toString("base64");
    });
}

async function getPackagingReports(deviceId) {
  const requestUrl = api_constants.getPackagingReports.replace(":deviceId", deviceId);
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
    },
  };

  return await axios(requestUrl, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.packagingReports;
    });
}

async function getAssemblyTests(assemblyId, skip, limit) {
  const loginResponse = JSON.parse(localStorage.getItem("user"));
  const requestUrl = api_constants.getAssemblyTests.replace(":assemblyId", assemblyId);
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginResponse.token}`,
    },
    params: {
      manufacturerId: loginResponse.user._id,
      skip: skip,
      limit: limit,
    },
  };

  return await axios(requestUrl, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.assemblyTests;
    });
}

async function getAssemblyTest(assemblyId, assemblyTestId) {
  const requestUrl = api_constants.getAssemblyTest
    .replace(":assemblyId", assemblyId)
    .replace(":assemblyTestId", assemblyTestId);
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
    },
  };

  return await axios(requestUrl, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.assemblyTest;
    });
}
async function getCalibrations(deviceId, skip, limit) {
  const loginResponse = JSON.parse(localStorage.getItem("user"))
  const requestUrl = api_constants.getCalibrations.replace(":deviceId", deviceId);
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${loginResponse.token}`,
    },
    params: {
      manufacturerId: loginResponse.user._id,
      skip: skip,
      limit: limit,
    },
  };
  return await axios(requestUrl, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.calibrations;
    });
}

async function getCalibration(deviceId, calibrationId) {
  const requestUrl = api_constants.getCalibration
    .replace(":deviceId", deviceId)
    .replace(":calibrationId", calibrationId);
  const requestOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
    },
  };

  return await axios(requestUrl, requestOptions)
    .then(handleResponse, (error) => Promise.reject(error))
    .then((data) => {
      return data.calibration;
    });
}

function handleResponse(response) {
  const data = response.data;
  if (!data.result.success) {
    const error = data.result;
    return Promise.reject(error);
  }
  return data;
}

function handleSecondSetOfLabelingResponse(response) {
  const data = response.data;
  if (!data) {
    const error = data.result;
    return Promise.reject(error);
  }
  return data;
}
