import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history } from "helpers";
import { userService } from "services";
import { logout } from "../logout";

export const login = createAsyncThunk(
  "authentication/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      return await userService.login(email, password);
    } catch (error) {
      if (error.message) {
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { loading: false, result: user, error: null }
  : { loading: false, result: null, error: null };

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    logoutProcess: {
      reducer: (_state, _action) => {
        logout();
        history.push("/auth/login");
        _state = undefined;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    });
  },
});

export const { logoutProcess } = authenticationSlice.actions;
export default authenticationSlice.reducer;
