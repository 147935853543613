import axios from "axios";
import api_constants from "apis/api_constants";

export const userService = {
  login,
};

async function login(email, password) {
  const requestOptions = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      email: email,
      password: password,
    }),
  };

  return await axios(api_constants.login, requestOptions)
    .then(handleLoginResponse, (error) => Promise.reject(error))
    .then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(data));
      return data;
    });
}

function handleLoginResponse(response) {
  const data = response.data;
  if (!data.result.success) {
    const error = data.result;
    return Promise.reject(error);
  }
  return data;
}
