import React, { useEffect } from "react";

import { ThemeProvider } from "@material-ui/styles";
import { theme } from "theme";

import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import routes from "routes";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { history } from "helpers";

import "mixins/chartjs";
import "mixins/validate";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/scss/main.scss";

function App() {
  useEffect(() => {
    if (history.location.pathname.includes("detail")) {
      history.push("/main/devices/Information");
    }
    if (!localStorage.user) {
      history.push("/auth/login");
    }
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>{renderRoutes(routes)}</Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
