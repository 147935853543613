/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth";
import MainLayout from "layouts/Main";
import DetailLayout from "layouts/Detail";
//import ErrorLayout from "./layouts/Error";

//Authentication related lazy components...
const Login = lazy(() => import("views/Auth/Login"));

//Main layout related lazy components...
const Devices = lazy(() => import("views/Main/Devices"));
const Assemblies = lazy(() => import("views/Main/Assemblies"));

//Detail layout related lazy components...
const Tests = lazy(() => import("views/Detail/Tests"));

// In the next sprint, improvements will be made on the route structure.

const Routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/auth/login" />, //"/main/subjects/Information"
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: Login,
      },
      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
  {
    path: "/main",
    component: MainLayout,
    routes: [
      {
        path: "/main/devices/:tab",
        exact: true,
        component: Devices,
      },
      {
        path: "/main/assemblies/:tab",
        exact: true,
        component: Assemblies,
      },
      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
  {
    path: "/detail",
    component: DetailLayout,
    routes: [
      {
        path: "/detail/tests/:type",
        exact: true,
        component: Tests,
      },
      {
        component: () => <Redirect to="/auth/login" />, //"/main/subjects/Information"
      },
    ],
  },
  {
    component: () => <Redirect to="/auth/login" />, //"/main/subjects/Information"
  },
];

export default Routes;
