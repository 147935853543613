import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceFilters: {
    assemblyStatus: null,
    calibrationStatus: null,
    startDate: null,
    endDate: null,
  },
  assemblyFilters: {
    assemblyStatus: null,
    oadUpdateStatus: null,
    startDate: null,
    endDate: null,
  },
};

const filtersSlice = createSlice({
  name: "pagination",
  initialState: initialState,
  reducers: {
    setDeviceFilters: (state, action) => {
      state.deviceFilters = action.payload;
    },
    setAssemblyFilters: (state, action) => {
      state.assemblyFilters = action.payload;
    },
  },
});

export const { setDeviceFilters, setAssemblyFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
