import { createTheme } from "@material-ui/core/styles";
import palette from "theme/palette";
import typography from "theme/typography";
import overrides from "theme/overrides";

const baseTheme = {
  palette,
  typography,
  overrides,
};

export const theme = createTheme(baseTheme);
