const MuiCardContent = {
  root: {
    padding: 15,
    "&:last-child": {
      paddingBottom: 15,
    },
  },
};

export default MuiCardContent;
