import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentType: "DEVICES",
};

const currentTypeSlice = createSlice({
  name: "currentType",
  initialState: initialState,
  reducers: {
    setCurrentType: (state, action) => {
      state.currentType = action.payload;
    },
  },
});

export const { setCurrentType } = currentTypeSlice.actions;
export default currentTypeSlice.reducer;
