import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { automaticService } from "services";
import { logout } from "../logout";

export const getCalibration = createAsyncThunk(
  "getCalibration",
  async ({ deviceId, calibrationId }, { rejectWithValue }) => {
    try {
      return await automaticService.getCalibration(deviceId, calibrationId);
    } catch (error) {
      if (error.message) {
        if (error?.response?.status === 401) {
          logout();
        }
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  result: null,
  loading: false,
  error: null,
  chosenGraph: 0,
  chosenIteration: 0,
};

const calibrationSlice = createSlice({
  name: "calibration",
  initialState: initialState,
  reducers: {
    clearCalibration: (state, _action) => {
      state.result = null;
    },
    setChosens: (state, action) => {
      state.chosenGraph = action.payload.chosenGraph;
      state.chosenIteration = action.payload.chosenIteration;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCalibration.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getCalibration.fulfilled, (state, action) => {
      state.loading = false;
      state.result = state.result
        ? state.result.concat(action.payload)
        : action.payload;
      state.error = null;
    });
    builder.addCase(getCalibration.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    });
  },
});

export const { clearCalibration, setChosens } = calibrationSlice.actions;
export default calibrationSlice.reducer;
