import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActionArea,
} from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import moment from "moment";
import { dateConstants } from "constants/dateConstants";
import {
  clearFirmwareUpdateList,
  getFirmwareUpdates,
} from "slices/firmwareUpdatesSlice";
import {
  clearFirmwarePagination,
  setFirmwarePagination,
} from "slices/paginationSlice";

const useStyles = makeStyles((theme) => ({
  main: {
    overflow: "auto",
    height: "100%",
  },
  root: {
    marginTop: 15,
  },
}));

const Popup = (props) => {
  const classes = useStyles();
  const { type, open, setOpen } = props;
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();

  const firmwareUpdatesList = props.firmwareUpdatesList;
  const paginationState = props.paginationState;

  const handleClose = () => {
    setSelected(null);
    setOpen(false);
    dispatch(clearFirmwareUpdateList());
    dispatch(clearFirmwarePagination());
  };

  const fetchMoreData = () => {
    if (
      paginationState.firmwarePagination.skip <
      firmwareUpdatesList.result.length
    ) {
      dispatch(
        setFirmwarePagination({
          skip:
            paginationState.firmwarePagination.skip +
            paginationState.firmwarePagination.limit,
          limit: paginationState.firmwarePagination.limit,
        })
      );
    }
  };

  const selectedFirmwareRevisionList = (id) => {
    setSelected(id);
  };

  useEffect(() => {
    if (open) {
      let promise = dispatch(
        getFirmwareUpdates({
          skip: paginationState.firmwarePagination.skip,
          limit: paginationState.firmwarePagination.limit,
        })
      );
      promise.then(unwrapResult);
    }
  }, [
    open,
    paginationState.firmwarePagination.skip,
    paginationState.firmwarePagination.limit,
    getFirmwareUpdates,
  ]);

  useEffect(() => {
    if (open) {
      dispatch(
        setFirmwarePagination({
          skip: 0,
          limit: 20,
        })
      );
    }
  }, [open, setFirmwarePagination]);

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      {type === 0 ? null : (
        <>
          <DialogTitle>List</DialogTitle>
          <DialogContent
            style={{ overflow: "hidden", height: "90vh", width: "50vw" }}
          >
            <Grid container spacing={3}>
              <Grid
                item
                xs={5}
                direction="column"
                style={{ maxHeight: "88vh", overflow: "auto" }}
              >
                <div id="scrollableDiv">
                  {firmwareUpdatesList.loading ? (
                    <CircularProgress style={{ marginTop: "100px" }} />
                  ) : isEmpty(firmwareUpdatesList.result) ? (
                    <Typography>Fimrware update list not found.</Typography>
                  ) : (
                    <InfiniteScroll
                      dataLength={firmwareUpdatesList.result.length}
                      next={fetchMoreData}
                      hasMore
                      scrollableTarget="scrollableDiv"
                    >
                      {firmwareUpdatesList.result.map((device, id) => {
                        return (
                          <Card className={classes.root}>
                            <CardActionArea
                              onClick={() => selectedFirmwareRevisionList(id)}
                            >
                              <CardContent>
                                <Grid container direction="column">
                                  <Grid item>
                                    <Typography>
                                      Creation Date :{" "}
                                      {moment(device.creationTime).format(
                                        dateConstants.DATE
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      Firmware Version :{" "}
                                      {device.firmwareRevision}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        );
                      })}
                    </InfiniteScroll>
                  )}
                </div>
              </Grid>
              <Grid item xs={7} style={{ maxHeight: "88vh", overflow: "auto" }}>
                {selected !== null
                  ? firmwareUpdatesList.result[selected].serialNumbers.map(
                    (serialNumber, id) => {
                      return (
                        <Card>
                          <CardHeader title="Serial Number" />
                          <CardContent>
                            <Typography>{serialNumber}</Typography>
                          </CardContent>
                        </Card>
                      );
                    }
                  )
                  : null}
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDevice: state.deviceState.currentDevice,
    currentAssembly: state.assemblyState.currentAssembly,
    firmwareUpdatesList: state.firmwareUpdatesState,
    paginationState: state.paginationState,
  };
};

export default connect(mapStateToProps, null)(Popup);
