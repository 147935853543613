import MuiButton from "theme/overrides/MuiButton";
import MuiCardActions from "theme/overrides/MuiCardActions";
import MuiCardContent from "theme/overrides/MuiCardContent";
import MuiCardHeader from "theme/overrides/MuiCardHeader";
import MuiChip from "theme/overrides/MuiChip";
import MuiIconButton from "theme/overrides/MuiIconButton";
import MuiInputBase from "theme/overrides/MuiInputBase";
import MuiLinearProgress from "theme/overrides/MuiLinearProgress";
import MuiListItem from "theme/overrides/MuiListItem";
import MuiListItemIcon from "theme/overrides/MuiListItemIcon";
import MuiOutlinedInput from "theme/overrides/MuiOutlinedInput";
import MuiPaper from "theme/overrides/MuiPaper";
import MuiTableCell from "theme/overrides/MuiTableCell";
import MuiTableHead from "theme/overrides/MuiTableHead";
import MuiTableRow from "theme/overrides/MuiTableRow";
import MuiToggleButton from "theme/overrides/MuiToggleButton";
import MuiTypography from "theme/overrides/MuiTypography";

const Overrides = {
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItem,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiToggleButton,
  MuiTypography,
};

export default Overrides;
