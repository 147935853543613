import React from "react";
import ReactDOM from "react-dom";
import App from "App";

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import reducers from "slices/reducers";

//required both in development and production
let middlewareList = [];
//required only in development
if (process.env.NODE_ENV !== "production") {
  let reduxLogger = require("redux-logger");
  middlewareList = [...middlewareList, reduxLogger.createLogger()];
}

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "alertState",
    "assembliesState",
    "assembliesViewState",
    "assemblyState",
    "authenticationState",
    "currentTypeViewState",
    "deviceState",
    "devicesState",
    "devicesViewState",
    "firmwareUpdatesState",
    "paginationState",
    "testsViewState",
  ],
};

const pReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middlewareList),
});

const persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
