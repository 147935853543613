import { colors } from "@material-ui/core";

const white = "#FFFFFF";

const Palette = {
  primary: {
    contrastText: white,
    dark: "#0DAEFF",
    main: "#0489CC",
    light: "#009CEB",
  },
  secondary: {
    contrastText: white,
    dark: colors.blue.A900,
    main: colors.blue.A700,
    light: colors.blue.A400,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: "#FF542E",
    light: colors.red[400],
  },
  text: {
    primary: "#6E6E6E",
    secondary: "#424242",
    tertiary: "#A3A3A3",
    link: "#304FFE",
  },
  link: colors.blue[800],
  icon: "#6E6E6E",
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  divider: colors.grey[200],
};

export default Palette;
