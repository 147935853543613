import { appReducer } from "slices/reducers/appReducer";

const Reducers = (state, action) => {
  if (action.type === "authentication/logout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default Reducers;
