import { combineReducers } from "redux";

import authenticationReducer from "slices/authenticationSlice";
import devicesReducer from "slices/devicesSlice";
import assembliesReducer from "slices/assembliesSlice";
import currentTypeReducer from "slices/currentTypeSlice";
import paginationReducer from "slices/paginationSlice";
import filtersReducer from "slices/filtersSlice";
import deviceReducer from "slices/deviceSlice";
import assemblyReducer from "slices/assemblySlice";
import firmwareUpdatesReducer from "slices/firmwareUpdatesSlice";
import calibrationsReducer from "slices/calibrationsSlice";
import calibrationReducer from "slices/calibrationSlice";
import assemblyTestsReducer from "slices/assemblyTestsSlice";
import assemblyTestReducer from "slices/assemblyTestSlice";

export const appReducer = combineReducers({
  authenticationState: authenticationReducer,
  devicesState: devicesReducer,
  assembliesState: assembliesReducer,
  currentTypeState: currentTypeReducer,
  paginationState: paginationReducer,
  filtersState: filtersReducer,
  deviceState: deviceReducer,
  assemblyState: assemblyReducer,
  firmwareUpdatesState: firmwareUpdatesReducer,
  calibrationsState: calibrationsReducer,
  calibrationState: calibrationReducer,
  assemblyTestsState: assemblyTestsReducer,
  assemblyTestState: assemblyTestReducer,
});
