import validate from "validate.js";

const regex = (value, options) => {
  const regExp = new RegExp(options.pattern); //not used for now

  if (!regExp.test(value)) {
    return options.message;
  }
};

const checked = (value, options) => {
  if (value !== true) {
    return options.message || "must be checked";
  }
};
//eslint-disable-next-line
validate.validators.email.PATTERN = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
validate.validators.email.message = "is not valid.";

const optionalEmail = (value) => {
  if (value === "") {
    return null;
  }
  return validate.single(value, { email: true });
};

validate.validators = {
  ...validate.validators,
  regex,
  checked,
  optionalEmail,
};
