import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { automaticService } from "services";
import { logout } from "../logout";

export const getAssembly = createAsyncThunk(
  "getAssembly",
  async ({ pcbSerialNumber }, { rejectWithValue }) => {
    try {
      return await automaticService.get_assembly(pcbSerialNumber);
    } catch (error) {
      if (error.message) {
        if (error?.response?.status === 401) {
          logout();
        }
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  currentAssembly : null,
};

const assemblySlice = createSlice({
  name: "assembly",
  initialState: initialState,
  reducers: {
    setCurrentAssembly: (state, action) => {
      state.currentAssembly = action.payload;
    },
    clearAssembly: (state, _action) => {
      state.currentAssembly = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssembly.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getAssembly.fulfilled, (state, action) => {
      state.loading = false;
      state.currentAssembly = action.payload;
      state.error = null;
    });
    builder.addCase(getAssembly.rejected, (state, action) => {
      state.loading = false;
      state.currentAssembly = null;
      state.error = action.payload;
    });
  },
});

export const { clearAssembly,setCurrentAssembly } = assemblySlice.actions;
export default assemblySlice.reducer;
