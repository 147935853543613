import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { automaticService } from "services";
import { logout } from "../logout";

export const getAssemblyTests = createAsyncThunk(
  "getAssemblyTests",
  async (
    {
      assemblyId,
      skip,
      limit,
    },
    { rejectWithValue }
  ) => {
    try {
      return await automaticService.getAssemblyTests(
        assemblyId,
        skip,
        limit,
      );
    } catch (error) {
      if (error.message) {
        if (error?.response?.status === 401) {
          logout();
        }
        //No response to request (No service)
        console.log("Internet Disconnected");
      } else {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  result: null,
  loading: false,
  error: null,
};

const assemblyTestsSlice = createSlice({
  name: "assemblyTests",
  initialState: initialState,
  reducers: {
    clearAssemblyTestsList: (state, _action) => {
      state.result = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssemblyTests.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(getAssemblyTests.fulfilled, (state, action) => {
      state.loading = false;
      state.result = state.result
        ? state.result.concat(action.payload)
        : action.payload;
      state.error = null;
    });
    builder.addCase(getAssemblyTests.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload;
    });
  },
});

export const { clearAssemblyTestsList } =
  assemblyTestsSlice.actions;
export default assemblyTestsSlice.reducer;
